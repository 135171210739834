/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.app {
  background-color: #f0f0f8 !important;
}

@media screen and (max-width: 500px) {
  #google_translate_element {
    left: 300px;
  }
}
.text-white {
  color: #fff;
}
th {
  text-align: center !important;
}
.profile-user {
  height: 30px;
}
.table-ctn {
  overflow-x: scroll;
}
body {
  /* font-family: "Roboto", sans-serif; */
  /* overflow: hidden; */
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  font-family: Verdana, sans-serif;
}
html {
  height: unset !important;
}
.side-menu {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.btn {
  transition: 0.3s ease;
  /* color: #fff !important; */
}
.btn-warning:hover {
  background-color: #ca8104 !important;
}
.btn-success:hover {
  background-color: rgb(65, 155, 111) !important;
}

.btn-danger {
  background-color: #8b0000 !important;
}
.btn-danger:hover {
  background-color: #630303 !important;
}
.btn-success {
  background-color: #378805 !important;
}
.dealer-side {
  margin-top: 130px !important;
}
.overview-section:first-child {
  margin-top: 0px !important;
}
.clickable {
  color: #184d6d;
  transition: 0.2s ease;
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline !important;
}
.admin-side {
  margin-top: 53px;
}
.mat-sort-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .dealer-side {
    margin-top: 100px !important;
  }

  .dealer-side2 {
    margin-top: 0px !important;
  }

  .sm-dealer-hidden-navbar {
    margin-bottom: -50px;
    padding-bottom: 0px !important;
    margin-top: -44px;
  }
}
html {
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  /* .dealer-side2 {
       margin-top: 30px;
    } */
}

@media screen and (max-width: 765px) {
  .dealer-side {
    margin-top: 120px !important;
  }
}
/*
  html, body { height: 100%; }
  body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */


#google_translate_element {
  position: fixed;
  top: 6px;
  z-index: 999999999999;
  right: 390px;
}


.swal2-title {
  font-size: 15px !important;
}

.swal2-icon {
  width: 4em !important;
  height: 4em !important;
}

.swal2-confirm {
  padding: 5px 21px !important;
  font-size: 14px !important;
}

.swal2-cancel {
  padding: 5px 21px !important;
  font-size: 14px !important;
}

.add-user {
  .mat-wrapper-dis {
    ::ng-deep {
      .mat-menu {
        // width: 100% !important;
        width: 380px !important;
        max-width: unset !important;
      }

      .mat-menu-panel {
        width: 380px !important;
        max-width: unset !important;
      }
    }
  }
}

@media screen and (max-width: 765px) {
  .view-text-value {
    font-size: 10px !important;
  }

  .view-title-label {
    font-size: 12px !important;
    font-weight: 800 !important;
  }

  .input-wrapper {
    input {
      font-size: 10px;
    }
  }

  .search-input {
    font-size: 10px;
  }
}



.content-card .load-overlay {
  display: none;
}
.content-card.loading {
  position: relative;
}
.content-card.loading .load-overlay {
  display: flex !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 25;
  background-color: rgba(255, 255, 255, 0.95);
  flex-direction: column;
}
.content-card.loading .load-overlay p {
  margin-top: 1em;
}